
export default {
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper')
  },
  props: {
    brands: {
      type: Array,
      default() { }
    }
  },
  data() {
    return {
      swiperOption: {
        initialSlide: 0,
        direction: 'horizontal',
        lazy: false,
        slidesPerView: 3.5,
        breakpointsInverse: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        themeColor: '#D4D4D4',
        spaceBetween: 20,
        breakpoints: {
          1024: {
            slidesPerView: 8,
            spaceBetween: 72
          },
          992: {
            slidesPerView: 6,
            spaceBetween: 90
          },
          768: {
            slidesPerView: 6.5,
            spaceBetween: 48
          },
          520: {
            slidesPerView: 6,
            spaceBetween: 20
          },
          410: {
            slidesPerView: 4.5,
            spaceBetween: 20
          },
          360: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }
      }
    }
  }
}
